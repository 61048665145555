import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { moneybird } from 'images'
import { employesUnderline } from 'images/koppelingen'
import Layout from '../../../../layouts'
import { graphql } from 'gatsby'
import marked from 'marked'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Video from "components/video"

const dummyKlantVerhaal = {
  content: [{
    title: 'Eenvoud met Employes & Moneybird',
    description: 'Employes is een salarispakket met een gebruiksvriendelijke interface, speciaal ontwikkeld voor ondernemers. Door de duidelijke stappenplannen en overzichtelijke schermen, hebben wij de manier van gegevens invoeren in ons systeem zo makkelijk gemaakt, dat jijzelf je salarisadministratie foutloos door onze software kan laten doen.'
    }, {
      title: 'Koppel je boekhouding',
      description: 'Na het doen van een verloning, dienen de kosten van je personeel verwerkt te worden in de boekhouding. Dat kun je doen door handmatig een memoriaalboeking te maken in Moneybird. Maar zoals één van de oprichters van Moneybird eerder schreef: "Uit eigen ervaring weet ik dat overtypen van de journaalposten een tijdrovend klusje is, maar met Employes dus niet meer!" De koppeling tussen Employes en Moneybird zorgt er namelijk voor dat de journaalposten automtisch aangemaakt worden.'
    },
    {
      video: 'https://www.youtube-nocookie.com/embed/K6FBrhzSzB4'
    },
    {
      title: 'Geef je Moneybird-adviseur gratis toegang',
      description: 'Employes werkt samen met verschillende Moneybird-adviseurs. Zij kunnen je helpen bij je boekhouding wanneer je er niet uitkomt. Op de website van Moneybird kun je een overzicht van adviseurs vinden. Je kunt ze gratis toegang geven tot Employes.'
    }]
}

class Moneybird extends Component {
  render() {

    const { edges } = this.props.data.allContentfulPartnerpage
    const allContent = edges[0].node.content.childMarkdownRemark.html

    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Moneybird & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatisch je journaalposten importeren in je Moneybird boekhouding met de koppeling van Employes." />
            <meta itemprop="name" content="Koppeling Moneybird & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatisch je journaalposten importeren in je Moneybird boekhouding met de koppeling van Employes." />
            <meta itemprop="image" content="https://employes.nl/static/meta-moneybird.png" />

            {/* OpenGraph tags */}
            <meta property="og:url" content="https://employes.nl/product/koppelingen/moneybird/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Koppeling Moneybird & Employes | Salarisadministratie" />
            <meta property="og:description" content="Automatisch je journaalposten importeren in je Moneybird boekhouding met de koppeling van Employes." />
            <meta property="og:image" content="https://employes.nl/static/meta-moneybird.png" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Koppeling Moneybird & Employes | Salarisadministratie" />
            <meta name="twitter:description" content="Automatisch je journaalposten importeren in je Moneybird boekhouding met de koppeling van Employes." />
            <meta name="twitter:image" content="https://employes.nl/static/meta-moneybird.png" />

          </Helmet>

          <header className="koppeling moneybird padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Moneybird aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline}/>
                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>
                    <img src={moneybird} alt="moneybird"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const isVideo = (firstObjectKey === 'video')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isVideo ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isVideo ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section"/>
                            ),
                            video: (
                                <Video videoSrcURL={section.video} videoTitle="Koppeling Moneybird en Employes salarisadministratie"/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Moneybird.propTypes = {
  data: PropTypes.object
}

export const assetQuery = graphql`
{
  allContentfulPartnerpage (filter: { partner: { eq: "Moneybird" } })  {
    edges {
      node {
        content {
          childMarkdownRemark{
            html
          }
        }
        partner
      }
    }
  }
}
`

export default Moneybird
